import type { Directive } from 'vue';
import ResizeObserver from 'resize-observer-polyfill';

// this type is based on ResizeObserverCallback
type DirectiveCallback = (...params: Parameters<ResizeObserverCallback>) => void | boolean;

/* eslint-disable no-param-reassign */
interface DirectiveContext {
    once?: boolean;
    immediate?: boolean;
    handler: DirectiveCallback;
    observer?: ResizeObserver;
}

interface DirectiveModifiers {
    once?: boolean;
    immediate?: boolean;
}

// as we are adding a property to the Node interface, we need to extend it
declare global {
    interface Node {
        resizeDirective?: DirectiveContext;
    }
}

function destroy(el: Node) {
    if (!el.resizeDirective) return;

    el.resizeDirective.observer?.disconnect();
    delete el.resizeDirective;
}

function updateObserver(el: Element) {
    const ctx = el.resizeDirective;
    if (!ctx) return;

    ctx.observer?.disconnect();

    ctx.observer = new ResizeObserver((list, observer) => {
        if (typeof ctx.handler !== 'function') return;

        // We delay execution of the handler to the next animation frame.
        // In case the handler manipulates the DOM, we don't end up in an error "ResizeObserver loop limit exceeded"
        // but have at most one execution per frame.
        window.requestAnimationFrame(() => {
            const res = ctx.handler(list, observer);

            if (res === false || ctx.once === true) {
                destroy(el);
            }
        });
    });

    ctx.observer.observe(el);
}

export default {
    beforeMount(
        el: Element,
        { modifiers: { immediate, once }, value }: { modifiers: DirectiveModifiers; value: DirectiveCallback },
    ) {
        el.resizeDirective = {
            once,
            immediate,
            handler: value,
        };

        updateObserver(el);
    },

    mounted(el: Element, { modifiers: { immediate } }: { modifiers: DirectiveModifiers }) {
        if (immediate && el.resizeDirective?.observer) {
            el.resizeDirective.handler([], el.resizeDirective.observer);
        }
    },

    updated(el: Element, { oldValue, value }: { oldValue: DirectiveCallback; value: DirectiveCallback }) {
        if (!el.resizeDirective || oldValue !== value) return;

        el.resizeDirective.handler = value;

        updateObserver(el);
    },

    unmounted: destroy,
} as Directive;
