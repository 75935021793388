import type { DataResponse, SuccessResponse } from '@/api/types';
import { gateway } from '..';

export function rateDocument(documentId: number, rate: number, reasonId?: number, comment?: string) {
    return gateway
        .post<DataResponse<unknown>>(`legacy-api/v1/documents/${documentId}/rate`, { rate, reasonId, comment })
        .then((response) => response.data);
}

export function storeDeleteReason(documentId: number, reasonId: number, comment?: string | null) {
    return gateway
        .post<DataResponse<SuccessResponse>>(`legacy-api/v1/documents/${documentId}/delete-reason`, {
            delete_reason_id: reasonId,
            comment,
        })
        .then((response) => response.data);
}
