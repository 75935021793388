import type { EventHint, ErrorEvent } from '@sentry/types';
import type { AxiosError } from 'axios';

/**
 * Because axios will trigger an Error when a request fails, the stack trace
 * is often going to be the same, grouping errors with different status codes
 * together in the same issue. We'll add more attributes to the event fingerprint
 * to have more granularity and separate different errors into different issues.
 */
export default function eventFingerprintProcessor(event: ErrorEvent, hint: EventHint) {
    const { originalException } = hint;

    if (typeof originalException === 'object' && originalException !== null && 'isAxiosError' in originalException) {
        const axiosException = originalException as AxiosError;
        /* eslint-disable-next-line no-param-reassign */
        event.fingerprint = [
            '{{ default }}',
            axiosException?.response?.status?.toString() ?? '',
            axiosException?.message,
        ];
    }

    return event;
}
