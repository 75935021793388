import type { App, Plugin } from 'vue';
import { merge } from 'lodash-es';
import VueI18Next from 'i18next-vue';
import i18next from 'i18next';
import ResourcesToBackend from 'i18next-resources-to-backend';
import { availableLocales, defaultNamespace, defaultLocale } from '@/utils/langUtils';
import dayjs from '@/plugins/dayjs';

const useSchoolTranslations = window.sdWindow.user?.is_pupil ?? false;

/**
 * Lazy loads a i18n resource based on the provided parameters.
 * Fallback handling is provided to not break the application rendering.
 */
function loadResource(language: string, namespace: string, flavor?: string) {
    const file = flavor
        ? import(`../lang/${language}/${flavor}/${namespace}.json`)
        : import(`../lang/${language}/${namespace}.json`);
    return file.catch(() => ({ default: {} })).then((_) => _.default as object);
}

i18next
    .use(
        /*
         * Instead of requesting the i18n files from the server,
         * this plugin will integrate the i18n files into the frontend bundle.
         * This allows to have HMR, versioning support and more customized file handling.
         */
        ResourcesToBackend(async (lng: string, ns: string) => {
            const normal = loadResource(lng, ns);
            if (!useSchoolTranslations) return normal;

            const school = loadResource(lng, ns, 'school');
            // we merge the school flavor into the normal translations and overwrite them.
            return merge(await normal, await school);
        }),
    )
    .init({
        lng: document.documentElement.lang,
        supportedLngs: availableLocales,
        fallbackLng: defaultLocale,
        ns: [defaultNamespace],
        defaultNS: defaultNamespace,
        interpolation: {
            escapeValue: false,
        },
        parseMissingKeyHandler(): string {
            return '';
        },
    })
    .then(() => {
        i18next.options.interpolation!.defaultVariables = {
            institution: useSchoolTranslations ? 'school' : 'uni',
        };
    });

export async function changeLanguage(lang: string) {
    dayjs.locale(lang);
    return i18next.changeLanguage(lang);
}

export function getLocale() {
    // TODO: this does not reflect the current language, only the initial one.
    return i18next.store.options.lng!;
}

export function getLanguageCode() {
    return getLocale()?.slice(0, 2);
}

export const i18n = i18next;

export default {
    install(app: App) {
        app.use(VueI18Next, {
            i18next,
            slotStart: '{{',
            slotEnd: '}}',
            legacyI18nOptionsSupport: true,
        });
    },
} as Plugin;
