import { type ErrorEvent } from '@sentry/types';

/**
 * Usercentrics throws many different errors in safari environments.
 * These contain a 408 statusCode.
 * By filtering for them and not returning an event, we can avoid reporting them.
 */
export default function usercentricsProcessor(event: ErrorEvent) {
    if (!event.message) return event;

    try {
        /*
         * we expect a message to be of format for unfixable usercentrics errors:
         * '[{},{"errorMessage":<some-string>,"statusCode":408}]'
         */
        const arr = JSON.parse(event.message);
        if (Array.isArray(arr) && arr.length === 2 && arr[1].statusCode === 408) {
            return null;
        }
    } catch (e) {
        return event;
    }

    return event;
}
