import { queryToObject, objectToQuery, searchParamsToObject } from '@/helpers/url';
import route from '@router/route';
import { storage } from '@/helpers/storage';
import { stringify as qsStringify } from 'query-string';

const storageKey = 'recentSearches';

const types = {
    CLEAR_RECENT_SEARCHES: 'CLEAR_RECENT_SEARCHES',
    PUSH_RECENT_SEARCH: 'PUSH_RECENT_SEARCH',
    REMOVE_RECENT_SEARCH: 'REMOVE_RECENT_SEARCH',
    UPDATE_RECENT_SEARCHES: 'UPDATE_RECENT_SEARCHES',
    SEARCH_IN_PROGRESS: 'SEARCH_IN_PROGRESS',
    URL_PARAMS: 'URL_PARAMS',
    REMOVE_URL_PARAMS: 'REMOVE_URL_PARAMS',
    APPLY_PARAMS: 'APPLY_PARAMS',
    SET_PARAMS: 'SET_PARAMS',
};

const getRecentSearches = () => {
    try {
        if (storage.getItem(storageKey)) {
            return JSON.parse(storage.getItem(storageKey));
        }
        return [];
    } catch (e) {
        return [];
    }
};

const applyUrlQuery = (state, query) => {
    state.urlParams = query; // TODO: remove urlParams eventually

    if (!state.isSearchPage) {
        window.location.href = `${route('search')}?${state.urlParams}`;
    } else if (window.history.replaceState) {
        window.router.push(`search?${state.urlParams}`);
        window.history.replaceState({ ...window.history.state }, null, null);
    }
};

/**
 * merges new params with current params, removes null/undefined/'' values and constructs new url params
 */
export function applySearchParams(stateParams, updateParams) {
    const params = {
        ...stateParams,
        p: null, // every param change, should reset the page (except page changes)
        ...updateParams,
    };

    // unset null/undefined/'' params
    const paramEntries = Object.entries(params).filter(
        (entry) => entry[1] !== null && entry[1] !== undefined && entry[1] !== '',
    );
    return Object.fromEntries(paramEntries);
}

const isSearchPage = route().current('search');

const state = {
    recentSearches: getRecentSearches(),
    /* @DEPRECATED - rely on searchParams instead, which is more handy to use */
    urlParams: isSearchPage ? window.location.search.replace(/^\?/g, '') : '',
    searchParams: isSearchPage ? queryToObject(window.location.search) : {},
    searchInProgress: false,
    isSearchPage,
};

const mutations = {
    /**
     * merges new params with current params, removes null/undefined values and constructs new url params
     */
    [types.APPLY_PARAMS](state, data) {
        state.searchParams = applySearchParams(state.searchParams, data);
        applyUrlQuery(state, objectToQuery(state.searchParams));
    },
    /**
     * replaces params as-is
     */
    [types.SET_PARAMS](state, data) {
        const filteredData = Object.entries(data).reduce(
            (acc, [key, value]) => (value !== '' ? { ...acc, [key]: value } : acc),
            {},
        );
        state.searchParams = filteredData;
        applyUrlQuery(state, objectToQuery(filteredData));
    },
    [types.PUSH_RECENT_SEARCH](state, data) {
        const url = data.data;
        const { locale } = data;

        const searchFilters = {};

        const keys = [
            'university_id',
            'course_id',
            'document_type',
            'has_ai_content',
            'type_ids',
            'institution_type',
            'max_academic_year',
            'min_academic_year',
            'phrase',

            // @DEPRECATED
            // 'content',
        ];

        keys.forEach((key) => {
            if (url[key]) {
                searchFilters[key] = url[key];
            }
        });

        const queryStringParams = qsStringify(searchFilters, { arrayFormat: 'bracket' });
        const link = `/${locale}/search?${queryStringParams}`;

        if (state.recentSearches.length > 0) {
            if (state.recentSearches[0].phrase !== url.phrase) {
                state.recentSearches.unshift({
                    phrase: url.phrase,
                    link,
                });
            }
        } else {
            state.recentSearches.unshift({
                phrase: url.phrase,
                link,
            });
        }

        try {
            storage.setItem(storageKey, JSON.stringify(state.recentSearches));
        } catch (e) {
            //
        }
    },

    [types.REMOVE_RECENT_SEARCH](state, index) {
        state.recentSearches.splice(index, 1);
        storage.setItem(storageKey, JSON.stringify(state.recentSearches));
    },

    [types.UPDATE_RECENT_SEARCHES](state, recentSearches) {
        storage.setItem(storageKey, JSON.stringify(recentSearches));
        state.recentSearches = recentSearches;
    },

    [types.CLEAR_RECENT_SEARCHES](state) {
        if (storage.getItem(storageKey)) {
            storage.removeItem(storageKey);
        }
        state.recentSearches = [];
    },
    /* @DEPRECATED - use APPLY_PARAMS instead */
    [types.REMOVE_URL_PARAMS](state, data) {
        const queryString = new URLSearchParams(state.urlParams);
        data.remove.forEach((value) => {
            queryString.delete(value);
        });

        state.urlParams = queryString.toString();
        state.searchParams = searchParamsToObject(queryString);

        if (window.history.pushState) {
            window.history.pushState(null, null, `search?${queryString.toString()}`);
        }
    },
    [types.SEARCH_IN_PROGRESS](state, payload) {
        state.searchInProgress = payload;
    },
    /* @DEPRECATED - use APPLY_PARAMS instead */
    [types.URL_PARAMS](state, data) {
        const queryString = new URLSearchParams(state.urlParams);
        if (data.university_id) {
            queryString.set('university_id', data.university_id);
        }

        if (data.phrase) {
            queryString.set('phrase', data.phrase);
        }

        if (data.course_id) {
            queryString.set('course_id', data.course_id);
        }

        if (data.content) {
            queryString.set('content', data.content);
            queryString.set('p', '1');
        }

        if (data.document_type) {
            queryString.delete('document_type[]');
            data.document_type.forEach((value) => {
                if (value !== '0') queryString.append('document_type[]', value);
            });
        }

        if (data.sort_type_documents) {
            queryString.set('sort_type_documents', data.sort_type_documents);
        }

        if (data.sort_type_courses) {
            queryString.set('sort_type_courses', data.sort_type_courses);
        }

        if (data.sort_type_flashcards) {
            queryString.set('sort_type_flashcards', data.sort_type_flashcards);
        }

        if (data.p && !data.content) {
            queryString.set('p', data.p);
        }

        state.searchParams = searchParamsToObject(queryString);
        applyUrlQuery(state, queryString.toString());
    },
};

const getters = {
    recentSearches: (state) => state.recentSearches,
    /* @DEPRECATED, use searchParams instead */
    urlParams: (state) => state.urlParams,
    searchParams: (state) => state.searchParams,
    searchPopUpType: (state) => state.searchPopUpType,
    searchInProgress: (state) => state.searchInProgress,
    isSearchPage: (state) => state.isSearchPage,
};

const actions = {
    push({ commit, rootState }, data) {
        const { locale } = rootState.ui;
        commit(types.PUSH_RECENT_SEARCH, { data, locale });
    },
    remove({ commit }, index) {
        commit(types.REMOVE_RECENT_SEARCH, index);
    },
    update({ commit }, recentSearches) {
        commit(types.UPDATE_RECENT_SEARCHES, recentSearches);
    },
    clear({ commit }) {
        commit(types.CLEAR_RECENT_SEARCHES);
    },
    applyParams({ commit }, data) {
        commit(types.APPLY_PARAMS, data);
    },
    setParams({ commit }, data) {
        commit(types.SET_PARAMS, data);
    },
    /* @DEPRECATED - use applyParams instead */
    addUrlParams({ commit }, data) {
        commit(types.URL_PARAMS, data);
    },
    /* @DEPRECATED - use setParams instead */
    removeUrlParam({ commit }, data) {
        commit(types.REMOVE_URL_PARAMS, data);
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
