/* eslint-disable @typescript-eslint/no-explicit-any */
import type { Plugin } from 'vue';
import { TinyEmitter } from 'tiny-emitter';
import type { FlashMessageOptions } from '@/components/FlashMessages/types';
import type { DropzoneFile } from 'dropzone-vue3';

/**
 * Colelction of events that are being emitted throuhg our global event bus
 * @todo define your event here
 */
export enum EventBusEvent {
    PROMPT_LOGIN = 'prompt-login',

    NOTIFY_INFO = 'notify-info',
    NOTIFY_SUCCESS = 'notify-success',
    NOTIFY_ERROR = 'notify-error',
    FILE_ADDED = 'file-added',
    FILE_SUCCESS = 'file-success',
    FILE_ERROR = 'file-error',
    FILE_SENDING = 'file-sending',
    QUEST_CLAIMED = 'quest-claimed',
}

/**
 * This types provides improved type checking for our event bus event
 * so that if you use `bus.emit('event-name', param1, param2)` you have type checks for the params in `bus.on('event-name', (param1, param2) => {})`
 * @todo define your custom event arguments here
 */
type CustomEventArguments = {
    [EventBusEvent.NOTIFY_INFO]: [string, FlashMessageOptions];
    [EventBusEvent.NOTIFY_SUCCESS]: [string, FlashMessageOptions];
    [EventBusEvent.NOTIFY_ERROR]: [string, FlashMessageOptions];
    [EventBusEvent.FILE_ADDED]: [DropzoneFile];
    [EventBusEvent.QUEST_CLAIMED]: [{ reward: number }];
};

// this type provides an empty arguments fallback for types that are not defined in CustomEventArguments
type EventArguments = CustomEventArguments & {
    [key in Exclude<EventBusEvent, keyof CustomEventArguments>]: [];
};

/**
 * Custom interface with improved type checking for our event bus events
 */
interface EventBusInterface extends TinyEmitter {
    on<E extends EventBusEvent>(event: E, callback: (...args: EventArguments[E]) => void, ctx?: any): this;
    once<E extends EventBusEvent>(event: E, callback: (...args: EventArguments[E]) => void, ctx?: any): this;
    off<E extends EventBusEvent>(event: E, callback?: (...args: EventArguments[E]) => void): this;
    emit<E extends EventBusEvent>(event: E, ...args: EventArguments[E]): this;
}

export const EventBus = new TinyEmitter() as EventBusInterface;

// add type definition inside vue components
declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        $bus: typeof EventBus;
    }
}

// add global window type
declare global {
    interface Window {
        /**
         * This is a legacy pattern and therefore should not be used anymore.
         * The global event bus is still used in some flashcards components.
         *
         * @deprecated import from @/plugins/bus instead */
        bus: typeof EventBus;
    }
}

export default {
    install(app) {
        // The global event bus is still used in some flashcards components.
        // It's a legacy pattern and therefore should not be used anymore.
        // TODO: remove eventually.
        window.bus = EventBus;

        // eslint-disable-next-line no-param-reassign
        app.config.globalProperties.$bus = EventBus;
    },
} as Plugin;
