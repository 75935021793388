/**
 * Currently we have some issues with our native ads, because of the ad library,
 * For now we will need to override one of the functions to be able to have our native ads working
 * This will be removed in the future.
 */
function overRideAsDefineTag() {
    const nativeObj = {
        body: { required: false },
        title: { required: true },
        image: { required: false },
        video: { required: false },
        icon: { required: true },
        sponsoredBy: { required: true },
        cta: { required: false },
    };
    /* eslint-disable */
    // AS code
    var ASCDP = window.ASCDP || {};
    (ASCDP.adS = ASCDP.adS || {}),
        (ASCDP.adS.defineTag = function (r) {
            var e,
                a = ASCDP.pageSet,
                i = ASCDP.adS,
                n = i.adElts[r] || i.initAdElt(r),
                t = n.relCount ? 'reload=true;reloadCount=' + n.relCount + ';' : '',
                o = r.replace('_rlSlot', '').replace('pub_', ''),
                s = i.getPlacementName(o),
                d = a.placementGroup + '-' + s;
            if (
                ((n.adSlot = document.querySelector('#' + r)),
                (n.plhldNode = document.getElementById('plhcss_' + o)),
                n.adSlot &&
                    (i.isVisible(r),
                    (n.posY = i.calcY(n.adSlot, n.adSlot.offsetTop)),
                    a.placeholder[r] &&
                        a.placeholder[r].clickUrl &&
                        ((n.adSlot.style.cursor = 'pointer'),
                        (n.adSlot.onclick = function () {
                            window.open(a.placeholder[r].clickUrl);
                        }))),
                (t +=
                    (n.masterSlot
                        ? n.masterSlot.getAttribute('data-target')
                        : n.adSlot
                        ? n.adSlot.getAttribute('data-target')
                        : '') || ''),
                document.querySelector('.hs-liveticker') && (t += ';betad_type=liveticker;'),
                (ASCDP.adtemplates.oracle[r] = { csm: '', isTFP: !1, rtb: '' }),
                i.slotSizeMap[r] || i.setSize(r),
                i.prtnKeys.once[o] ? (e = o) : i.prtnKeys.once[s] && i.slotSizeMap[r] === i.slotSizeMap[s] && (e = s),
                i.prtnKeys.once[e])
            )
                for (var c in i.prtnKeys.once[e])
                    if (i.prtnKeys.once[e].hasOwnProperty(c)) {
                        if ('pbObj' === c) {
                            for (var p in i.prtnKeys.once[e].pbObj)
                                i.prtnKeys.once[e].pbObj.hasOwnProperty(p) &&
                                    ((n[p] = i.prtnKeys.once[e].pbObj[p]),
                                    'pbAdCode' !== p && (t += p + '=' + i.prtnKeys.once[e].pbObj[p] + ';'));
                            delete i.prtnKeys.once[e].pbObj;
                        } else
                            'hbDeal' === c
                                ? ((t += i.prtnKeys.once[e].hbDeal + ';'), delete i.prtnKeys.once[e].hbDeal)
                                : i.prtnKeys.once[e].teadsOutstream
                                ? ((n.teadsOutstream = i.prtnKeys.once[e].teadsOutstream),
                                  delete i.prtnKeys.once[e].teadsOutstream)
                                : ((t += c + '=' + i.prtnKeys.once[e][c] + ';'), delete i.prtnKeys.once[e][c]);
                        '{}' === JSON.stringify(i.prtnKeys.once[e]) && delete i.prtnKeys.once[e];
                    }
            for (var l, g, u = t.split(';') || [], b = 0; b < u.length; b++)
                u[b].match('=')
                    ? ((g = (l = u[b].split('='))[0]),
                      (n.targetObject[g] = n.targetObject[g] || []),
                      (n.targetObject[g] = n.targetObject[g].concat(l[1].toString().split(','))))
                    : ((n.targetObject.misc = n.targetObject.misc || []), n.targetObject.misc.push(u[b].trim()));
            (n.targetObject.pos = s),
                (n.targetObject.contId = o),
                (n.targetObject.pgName = a.pageName),
                (n.targetObject.prgrnd = Math.floor(100 * Math.random())),
                (n.targetObject.contId_prgrnd = n.targetObject.contId + '_' + n.targetObject.prgrnd),
                (n.targetObject.type = a.pageTypes[a.view]),
                (n.targetObject.btf = r.match('_btf') ? 'true' : 'false');
            s = {
                disablePsa: !0,
                sizeMapping: i.slotSizeMap[r],
                targetId: r,
                tagId: '',
                invCode: '',
                globalPlacementId: '',
                forceCreativeId: void 0,
                native: r.includes('native') || r.includes('video') ? nativeObj : void 0,
                rendererOptions: void 0,
                keywords: n.targetObject,
                allowedFormats: ['banner'],
                initCollapsed: { expandBy: 'creative' },
            };
            if (
                (ASCDP.pageSet.placementId && ASCDP.pageSet.placementId[d]
                    ? (s.tagId = ASCDP.pageSet.placementId[d])
                    : (s.invCode = s.globalPlacementId = d),
                a.cookies[o] && (s.forceCreativeId = a.cookies[o]),
                a.isAMP &&
                    ((s.sizeMapping[0].sizes = s.sizeMapping[0].sizes.filter(function (e) {
                        e = e[0] + 'x' + e[1];
                        return '1000x300' != e && '320x480' != e;
                    })),
                    i.prevOn &&
                        (d = new RegExp('[?|&|;|=]' + r + ':([^&;]+?)(&|#|,|$|;)').exec(
                            (window.context && window.context.canonicalUrl ? window.context : window).location.search,
                        )) &&
                        (s.forceCreativeId = d[1])),
                (s.sizeMapping[0].sizes = s.sizeMapping[0].sizes.sort(function (e, t) {
                    return e[0] == t[0] ? t[1] - e[1] : t[0] - e[0];
                })),
                'number' == typeof n.posY)
            ) {
                let e, t;
                (e = n.plhldNode ? n.adSlot.offsetHeight / 2 : s.sizeMapping[0].sizes[0][1] / 2),
                    6e3 < (t = 100 * Math.ceil((e + n.posY) / 100)) && (t = 6e3),
                    (n.targetObject.depthspeed = t + '-' + a.netSpeed);
            }
            var S,
                m,
                d = s.sizeMapping[0].sizes.join('|');
            if (
                (d.match(/640,360|16,9|9,16/) &&
                    !r.match(/xstream/i) &&
                    ((S =
                        !a.articleLocked &&
                        (('d' === a.view ? a.outstreamId : a.outstreamIdMobile).toString() + ',').match(
                            new RegExp(o + ',', 'i'),
                        )),
                    (m =
                        'dooh' === a.view ||
                        (window.adSSetup && (!adSSetup.hasOwnProperty('isArticle') || adSSetup.isArticle))),
                    d.match(/16,9|9,16/) || (m && S && !a.outstreamSlot)
                        ? (a.adaVideo &&
                              a.adaVideo.active &&
                              a.country.match(/^(de)/i) &&
                              i.loadModules(['ada'], function () {
                                  ASCDP.partners.ada(!0);
                              }),
                          -1 === d.indexOf('1,1') && i.slotSizeMap[o][0].sizes.push([1, 1]),
                          (n.targetObject.slotHasOutstream = 'true'),
                          (n.hasOutstream = !0),
                          (n.targetObject.hasPAUL = 'true'),
                          (n.targetObject.orientation = -1 < d.indexOf('9,16') ? 'vertical' : 'horizontal'),
                          s.allowedFormats.push('video'),
                          (s.rendererOptions = i.outstreamRendererOptions(n.targetObject.orientation)))
                        : (s.sizeMapping[0].sizes = s.sizeMapping[0].sizes.filter(function (e) {
                              return '640x360' != e[0] + 'x' + e[1];
                          }))),
                (r.match(/betad_btf/i) ||
                    ('13513' === a.memberId.toString() && -1 !== d.indexOf('-1,-1')) ||
                    ('7823' === a.memberId.toString() &&
                        -1 === r.indexOf('pub_') &&
                        ((-1 < r.indexOf('mrec') && -1 !== d.indexOf('300,250')) ||
                            (-1 < r.indexOf('sky') && -1 !== d.indexOf('300,600')) ||
                            (-1 < r.indexOf('banner') && -1 !== d.indexOf('320,75')) ||
                            -1 !== d.indexOf('970,250')))) &&
                    (s.allowedFormats.push('native'),
                    -1 === s.sizeMapping[0].sizes.join('|').indexOf('1,1') && s.sizeMapping[0].sizes.push([1, 1]),
                    (s.sizeMapping[0].sizes = s.sizeMapping[0].sizes.filter(function (e) {
                        return '-1x-1' != e[0] + 'x' + e[1];
                    })),
                    '13513' !== a.memberId.toString() &&
                        (s.native = {
                            title: { required: !0 },
                            body: { required: !1 },
                            image: { required: !0 },
                            icon: { required: !1 },
                            sponsoredBy: { required: !1 },
                            cta: { required: !1 },
                            clickUrl: { required: !0 },
                        })),
                7823 !== a.memberId ||
                    ('amp' !== a.view && !o.match(/betad_btf/)) ||
                    -1 !== d.indexOf('9,9') ||
                    (i.slotSizeMap[o][0].sizes.push([9, 9]), (d = i.slotSizeMap[o][0].sizes.join('|'))),
                -1 !== d.indexOf('9,9') && (n.targetObject.genericSize = 'true'),
                (!ASCDP.pageSet.isHome || (ASCDP.pageSet.isHome && ASCDP.pageSet.retgtHome)) &&
                    ASCDP.pageSet.blockerMatrix[r] &&
                    i.blockMatrixElements++,
                a.autoReloader)
            )
                for (var O = 0; O < a.paulArr.length; O++) {
                    var f = new RegExp(a.paulArr[O] + '(?!_)', 'i');
                    o.match(f) && (n.hasPAUL = !0);
                }
            i.astEvents.forEach(function (a) {
                apntag.onEvent(a, r, function (e, t) {
                    i.debug &&
                        console.log('%cADLIB: callback ' + a + ' executed for ' + r, ASCDP.adS.logColor, arguments),
                        a.match(/ad(BadRequest|RequestFailure|Error)/i)
                            ? i.loadModules(['adError'], function () {
                                  i.adError(r, e, t, a);
                              })
                            : i.loadModules([a], function () {
                                  i[a](r, e);
                              });
                });
            }),
                (n.xandrTag = apntag.defineTag(s));
        });
    /* eslint-enable */
}

export const defineTag = overRideAsDefineTag;
