import eventFingerprintProcessor from './eventFingerprintProcessor';
import userDataProcessor from './userDataProcessor';
import usercentricsProcessor from './usercentricsProcessor';
import axiosProcessor from './axiosProcessor';

// NOTE: the order defines their execution order
export default {
    axiosProcessor,
    eventFingerprintProcessor,
    usercentricsProcessor,
    userDataProcessor,
};
