import {
    fetchUserJobSwipeList,
    fetchJobsVibeByDate,
    fetchJobsVibeByScore,
    fetchAllJobList,
    saveOrUpdateUserJobAction,
    getUserNotificationsCount,
    markUserNotificationAsRead,
    getCompanyJobsById,
    getUserHasJobVibe,
} from '@/api/backend/career';

const types = {
    // setGroupCategoryId & clearGroupCategoryId
    GROUP_CATEGORY_ID: 'GROUP_CATEGORY_ID',

    // getSwipeJobs
    GET_JOBS_SWIPE_LIST: 'GET_JOBS_SWIPE_LIST',
    UPDATE_JOBS_SWIPE_PROGRESS: 'UPDATE_JOBS_SWIPE_PROGRESS',
    UPDATE_META_DATA_SWIPE_LIST: 'UPDATE_META_DATA_SWIPE_LIST',

    // getJobVibesByScore & getJobVibesByDate
    GET_VIBED_JOBS_LIST: 'GET_VIBED_JOBS_LIST',
    UPDATE_JOBS_VIBE_PROGRESS: 'UPDATE_JOBS_VIBE_PROGRESS',
    UPDATE_META_DATA_VIBED_JOBS: 'UPDATE_META_DATA_VIBED_JOBS',
    UPDATE_CAN_LOAD_MORE_VIBED_JOBS: 'UPDATE_CAN_LOAD_MORE_VIBED_JOBS',
    UPDATE_DEFAULT_ACTION: 'UPDATE_DEFAULT_ACTION',

    // getAllJobs
    GET_ALL_JOBS_LIST: 'GET_ALL_JOBS_LIST',
    UPDATE_JOBS_ALL_PROGRESS: 'UPDATE_JOBS_ALL_PROGRESS',
    UPDATE_META_DATA_BY_LIST: 'UPDATE_META_DATA_BY_LIST',
    UPDATE_CAN_LOAD_MORE_BY_ALL_LIST: 'UPDATE_CAN_LOAD_MORE_BY_ALL_LIST',

    // saveJobVibeAction
    SAVE_OR_UPDATE_USER_JOB_ACTION: 'SAVE_OR_UPDATE_USER_JOB_ACTION',
    JOB_VIBED_LIST_UPDATED: 'JOB_VIBED_LIST_UPDATED',
    SAVED_JOB_IN_PROGRESS: 'SAVED_JOB_IN_PROGRESS',
    UPDATE_SWIPED_JOBS_LIST_CURRENT_INDEX: 'UPDATE_SWIPED_JOBS_LIST_CURRENT_INDEX',
    UPDATE_CAREER_NOTIFICATIONS_TOTAL: 'UPDATE_CAREER_NOTIFICATIONS_TOTAL',
    UPDATE_JOB_IN_ALL_JOBS_LIST: 'UPDATE_JOB_IN_ALL_JOBS_LIST',

    // ??
    GET_JOBS_LIST_BY_DATE: 'GET_JOBS_LIST_BY_DATE',
    GET_VIBED_JOBS_LIST_CONTEXT: 'GET_VIBED_JOBS_LIST_CONTEXT',
    SET_SWIPE_LIST_FLAG_ERROR: 'SET_SWIPE_LIST_FLAG_ERROR',
    SCROLL_TO_SAVED_JOBS: 'SCROLL_TO_SAVED_JOBS',

    // company
    COMPANY_JOBS: 'COMPANY_JOBS',

    // company
    USER_HAS_JOB_VIBE: 'USER_HAS_JOB_VIBE',
};

const state = {
    groupCategoryId: -1,
    infoTypes: ['stepstone', 'preferences', 'empty'],
    allowedOptions: [
        'job_type',
        'work_environment',
        'location',
        'employment_type',
        'industry',
    ],
    defaultAction: 'getJobVibesByDate',
    savedJobInProgress: false,
    jobsSwipeList: {
        list: [],
        progress: false,
        metaData: null,
    },
    jobsVibedList: {
        list: [],
        progress: false,
        canLoadMore: true,
        totalCount: 0,
        metaData: null,
        type: 'date',
    },
    allJobsList: {
        list: [],
        progress: false,
        canLoadMore: true,
        totalCount: 0,
        metaData: null,
    },
    jobsVibedListUpdated: false,
    swipedJobsListCurrentIndex: 0,
    swipeListErrorFlag: false,
    scrollToSavedJobs: false,
    totalNotifications: 0,
    companyJobs: [],
    userHasJobVibe: false,
};

const mutations = {
    // setGroupCategoryId & clearGroupCategoryId
    [types.GROUP_CATEGORY_ID](state, val) {
        state.groupCategoryId = val;
    },

    // getSwipeJobs
    [types.GET_JOBS_SWIPE_LIST](state, { data }) {
        state.jobsSwipeList.list.push(...data);
    },
    [types.UPDATE_JOBS_SWIPE_PROGRESS](state, val) {
        state.jobsSwipeList.progress = val;
    },
    [types.UPDATE_META_DATA_SWIPE_LIST](state, payload) {
        state.jobsSwipeList.metaData = payload;
    },

    // getJobVibesByScore & getJobVibesByDate
    [types.GET_VIBED_JOBS_LIST](state, { shouldAppendResult, data }) {
        if (shouldAppendResult) {
            state.jobsVibedList.list.push(...data);
        } else {
            state.jobsVibedList.list = data;
        }
    },
    [types.UPDATE_JOBS_VIBE_PROGRESS](state, val) {
        state.jobsVibedList.progress = val;
    },
    [types.UPDATE_META_DATA_VIBED_JOBS](state, payload) {
        state.jobsVibedList.metaData = payload;
    },
    [types.UPDATE_CAN_LOAD_MORE_VIBED_JOBS](state, payload) {
        state.jobsVibedList.canLoadMore = payload;
    },
    [types.UPDATE_DEFAULT_ACTION](state, payload) {
        state.defaultAction = payload;
    },

    // getAllJobs
    [types.GET_ALL_JOBS_LIST](state, payload) {
        state.allJobsList.list.push(...payload);
    },
    [types.UPDATE_JOBS_ALL_PROGRESS](state, val) {
        state.allJobsList.progress = val;
    },
    [types.UPDATE_META_DATA_BY_LIST](state, payload) {
        state.allJobsList.metaData = payload;
    },
    [types.UPDATE_CAN_LOAD_MORE_BY_ALL_LIST](state, payload) {
        state.allJobsList.canLoadMore = payload;
    },

    // saveJobVibeAction
    [types.SAVE_OR_UPDATE_USER_JOB_ACTION](state, { isSwipedJob, data }) {
        const jobsList = isSwipedJob ? state.jobsSwipeList.list : state.jobsVibedList.list;
        const index = jobsList.findIndex((item) => {
            return item.id === data?.job_id;
        });

        if (index !== -1) {
            jobsList.splice(index, 1);
        }
    },
    [types.JOB_VIBED_LIST_UPDATED](state, payload) {
        state.jobsVibedListUpdated = payload;
    },
    [types.UPDATE_SWIPED_JOBS_LIST_CURRENT_INDEX](state, payload) {
        state.swipedJobsListCurrentIndex = payload;
    },
    [types.SAVED_JOB_IN_PROGRESS](state, val) {
        state.savedJobInProgress = val;
    },
    [types.SET_SWIPE_LIST_FLAG_ERROR](state, payload) {
        state.swipeListErrorFlag = payload;
    },
    [types.SCROLL_TO_SAVED_JOBS](state, payload) {
        state.scrollToSavedJobs = payload;
    },
    [types.UPDATE_CAREER_NOTIFICATIONS_TOTAL](state, payload) {
        state.totalNotifications = payload;
    },
    [types.COMPANY_JOBS](state, payload) {
        state.companyJobs = payload;
    },
    [types.USER_HAS_JOB_VIBE](state, payload) {
        state.userHasJobVibe = payload;
    },
    [types.UPDATE_JOB_IN_ALL_JOBS_LIST](state, payload) {
        /* eslint-disable no-param-reassign */
        state.allJobsList.list.forEach((item) => {
            if (item.id === payload.job_id) {
                item.action = payload.action;
            }
        });
        /* eslint-enable no-param-reassign */
    },
};

const actions = {
    async getSwipeJobs({ commit }, { page }) {
        commit(types.UPDATE_JOBS_SWIPE_PROGRESS, true);

        return fetchUserJobSwipeList(page)
            .then((response) => {
                const { data, meta, success } = response.jobs;
                if (success !== undefined && !success) {
                    commit(types.SET_SWIPE_LIST_FLAG_ERROR, true);
                } else {
                    commit(types.UPDATE_META_DATA_SWIPE_LIST, meta);
                    commit(types.GET_JOBS_SWIPE_LIST, { data });
                }
            })
            .catch(() => commit(types.SET_SWIPE_LIST_FLAG_ERROR, true))
            .finally(() => {
                commit(types.UPDATE_JOBS_SWIPE_PROGRESS, false);
            });
    },

    async getJobVibesByScore({ commit }, { page, action, shouldAppendResult }) {
        commit(types.UPDATE_JOBS_VIBE_PROGRESS, true);
        commit(types.UPDATE_DEFAULT_ACTION, action);
        return fetchJobsVibeByScore(page)
            .then((response) => {
                const { data, meta } = response.jobs;
                commit(types.UPDATE_META_DATA_VIBED_JOBS, meta);
                commit(types.UPDATE_CAN_LOAD_MORE_VIBED_JOBS, meta ? meta.current_page < meta.last_page : false);
                commit(types.GET_VIBED_JOBS_LIST, { shouldAppendResult, data });
            })
            .finally(() => {
                commit(types.UPDATE_JOBS_VIBE_PROGRESS, false);
            });
    },

    async getJobVibesByDate({ commit }, { page, action, shouldAppendResult }) {
        commit(types.UPDATE_JOBS_VIBE_PROGRESS, true);
        commit(types.UPDATE_DEFAULT_ACTION, action);
        return fetchJobsVibeByDate(page)
            .then((response) => {
                const { data, meta } = response.jobs;
                commit(types.UPDATE_META_DATA_VIBED_JOBS, meta);
                commit(types.UPDATE_CAN_LOAD_MORE_VIBED_JOBS, meta ? meta.current_page < meta.last_page : false);
                commit(types.GET_VIBED_JOBS_LIST, { shouldAppendResult, data });
            })
            .finally(() => {
                commit(types.UPDATE_JOBS_VIBE_PROGRESS, false);
            });
    },

    async getAllJobs({ commit }, { page }) {
        commit(types.UPDATE_JOBS_ALL_PROGRESS, true);
        return fetchAllJobList(page)
            .then((response) => {
                const { data, meta } = response.jobs;
                commit(types.UPDATE_META_DATA_BY_LIST, meta);
                commit(types.UPDATE_CAN_LOAD_MORE_BY_ALL_LIST, meta ? meta.current_page < meta.last_page : false);
                commit(types.GET_ALL_JOBS_LIST, data);
            })
            .finally(() => {
                commit(types.UPDATE_JOBS_ALL_PROGRESS, false);
            });
    },

    async saveJobVibeAction({ commit, state }, { isSwipedJob, data }) {
        if (data.job_id) {
            commit(types.SAVED_JOB_IN_PROGRESS, true);
            if (data.action === 1) {
                commit(types.JOB_VIBED_LIST_UPDATED, true);
            }
            saveOrUpdateUserJobAction(data)
                .then(() => {
                    commit(types.JOB_VIBED_LIST_UPDATED, false);
                })
                .finally(() => {
                    commit(types.SAVED_JOB_IN_PROGRESS, false);
                });
            commit(types.UPDATE_SWIPED_JOBS_LIST_CURRENT_INDEX, state.swipedJobsListCurrentIndex + 1);
        }
        commit(types.SAVE_OR_UPDATE_USER_JOB_ACTION, { isSwipedJob, data });
        commit(types.UPDATE_JOB_IN_ALL_JOBS_LIST, data);
    },

    setGroupCategoryId({ commit }, id) {
        commit(types.GROUP_CATEGORY_ID, id);
    },
    clearGroupCategoryId({ commit }) {
        commit(types.GROUP_CATEGORY_ID, -1);
    },
    setScrollToSavedJobs({ commit }, value) {
        commit(types.SCROLL_TO_SAVED_JOBS, value);
    },
    fetchUserCareerNotifications({ commit }) {
        getUserNotificationsCount().then((data) => {
            commit(types.UPDATE_CAREER_NOTIFICATIONS_TOTAL, data?.unread_count ?? 0);
        });
    },
    markUserCareerNotificationAsRead({ commit }) {
        markUserNotificationAsRead().then(() => {
            commit(types.UPDATE_CAREER_NOTIFICATIONS_TOTAL, 0);
        });
    },
    getCompanyJobsById({ commit }, companyId) {
        getCompanyJobsById(companyId).then((response) => {
            const { data } = response.jobs;
            commit(types.COMPANY_JOBS, data ?? []);
        });
    },
    getUserHasJobVibe({ commit }) {
        getUserHasJobVibe().then((response) => {
            commit(types.USER_HAS_JOB_VIBE, response?.isEligibleUser ?? false);
        });
    },
};

const getters = {
    // setGroupCategoryId & clearGroupCategoryId
    groupCategoryId: (state) => state.groupCategoryId,

    // getAllJobs
    allJobsList: (state) => {
        return state.allJobsList.list || [];
    },
    allJobsProgress: (state) => {
        return state.allJobsList.progress;
    },
    canLoadMoreAllJobs: (state) => {
        return state.allJobsList.canLoadMore;
    },

    // getSwipeJobs
    swipeJobsList: (state) => {
        return state.jobsSwipeList.list || [];
    },
    swipeJobsProgress: (state) => {
        return state.jobsSwipeList.progress;
    },
    swipeJobsLastPage: (state) => {
        return state.jobsSwipeList.metaData.lastPage;
    },
    swipeListCurrentPage: (state) => {
        return state.jobsVibedList.metaData?.current_page ?? null;
    },

    // getJobVibesByScore & getJobVibesByDate
    vibedJobsList: (state) => {
        return state.jobsVibedList.list || [];
    },
    vibedJobsProgress: (state) => {
        return state.jobsVibedList.progress;
    },
    canLoadMore: (state) => {
        return state.jobsVibedList.canLoadMore;
    },
    currentJobsVibedPage: (state) => {
        return state.jobsVibedList.metaData.current_page ?? 1;
    },
    getJobVibedListUpdated: (state) => {
        return state.jobsVibedListUpdated;
    },
    getDefaultAction: (state) => {
        return state.defaultAction;
    },

    // saveJobVibeAction
    getSwipedJobsListCurrentIndex: (state) => {
        return state.swipedJobsListCurrentIndex;
    },

    infoTypeCards: (state) => {
        return state.infoTypes;
    },

    allowedOptionsType: (state) => {
        return state.allowedOptions;
    },
    isSavedJobInProgress: (state) => {
        return state.savedJobInProgress;
    },
    hasSwipeListError: (state) => {
        return state.swipeListErrorFlag;
    },
    getScrollToSavedJobs: (state) => {
        return state.scrollToSavedJobs;
    },
    totalCareerNotifications: (state) => state.totalNotifications,
    companyJobs: (state) => state.companyJobs,
    userHasJobVibe: (state) => state.userHasJobVibe,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
