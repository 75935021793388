import route from '@router/route';
import {
    deletePost,
    editPost,
    reportPost,
    createComment,
    deleteComment,
    editComment,
    reportComment,
    toggleBestComment,
} from '@/api/backend/post';

const types = {
    ADD_COMMENT_TO_POST: 'ADD_COMMENT_TO_POST',
    PROPAGATE_IDENTITY_CHANGE: 'PROPAGATE_IDENTITY_CHANGE',
    REMOVE_COMMENT_FROM_POST: 'REMOVE_COMMENT_FROM_POST',
    SET_POST: 'SET_POST',
    SET_REPORTED_POST: 'SET_REPORTED_POST',
    SET_REPORTED_COMMENT: 'SET_REPORTED_COMMENT',
    TOGGLE_BEST_COMMENT: 'TOGGLE_BEST_COMMENT',
    UPDATE_COMMENT_IN_POST: 'UPDATE_COMMENT_IN_POST',
    UPDATE_POST: 'UPDATE_POST',
};

const state = {
    post: null,
};

const mutations = {
    [types.ADD_COMMENT_TO_POST](state, comment) {
        state.post.comments.push(comment);
        state.post.totalComments += 1;
    },
    [types.PROPAGATE_IDENTITY_CHANGE](state, { user, isAnonymous }) {
        if (state.post.isOwner) {
            if (isAnonymous) {
                state.post.postDetails.anonymousUser = user;
                state.post.postDetails.user = null;
            } else {
                state.post.postDetails.user = user;
                state.post.postDetails.anonymousUser = null;
            }
            state.post.postDetails.isAnonymous = isAnonymous;
        }
        /* eslint-disable no-param-reassign */
        state.post.comments.forEach((comment) => {
            if (comment.isOwner) {
                if (isAnonymous) {
                    comment.anonymousUser = user;
                    comment.user = null;
                } else {
                    comment.user = user;
                    comment.anonymousUser = null;
                }
                comment.isAnonymous = isAnonymous;
            }
        });
        /* eslint-enable */
    },
    [types.REMOVE_COMMENT_FROM_POST](state, { commentId }) {
        const commentIndex = state.post.comments.findIndex((comment) => {
            return comment.id === commentId;
        });

        if (commentIndex === -1) {
            throw new Error('Comment not found');
        }

        state.post.comments.splice(commentIndex, 1);
    },
    [types.SET_POST](state, post) {
        state.post = post;
    },
    [types.SET_REPORTED_COMMENT](state, { commentId }) {
        const comment = state.post.comments.find((item) => {
            return item.id === commentId;
        });
        comment.isReported = true;
        comment.userReport = true;
    },
    [types.SET_REPORTED_POST](state) {
        state.post.postDetails.isReported = true;
        state.post.postDetails.userReport = true;
    },
    [types.TOGGLE_BEST_COMMENT](state, commentId) {
        const bestComment = state.post.comments.find((comment) => comment.id === commentId);

        if (bestComment === undefined) {
            throw new Error('Comment not found, cannot toggle best comment.');
        }

        state.post.postDetails.hasBestAnswer = !state.post.postDetails.hasBestAnswer;
        bestComment.isBest = !bestComment.isBest;
    },
    [types.UPDATE_COMMENT_IN_POST](state, comment) {
        const commentIndex = state.post.comments.findIndex((postComment) => {
            return postComment.id === comment.id;
        });

        if (commentIndex === -1) {
            throw new Error('Comment not found, cannot update comment.');
        }

        state.post.comments[commentIndex] = comment;
    },
    [types.UPDATE_POST](state, post) {
        // eslint-disable-next-line no-param-reassign
        post.comments = state.post.comments;
        state.post = post;
    },
};

const actions = {
    // eslint-disable-next-line no-unused-vars
    async deletePost({ commit }, { context, postId }) {
        await deletePost(context, postId);
        window.location.replace(route('newsfeed.show').url());
    },
    async editPost({ commit }, { context, post }) {
        const response = await editPost(context, post);
        commit(types.UPDATE_POST, response.data);
        return { post: response.data };
    },
    async reportPost({ commit }, { context, postId, reason, additional }) {
        await reportPost(context, postId, reason, additional);
        commit(types.SET_REPORTED_POST);
    },
    async createComment({ commit }, { context, comment }) {
        const response = await createComment(context, comment);
        commit(types.ADD_COMMENT_TO_POST, response.data);
        return { comment: response.data };
    },
    async deleteComment({ commit }, { context, commentId }) {
        await deleteComment(context, commentId);
        commit(types.REMOVE_COMMENT_FROM_POST, { commentId });
    },
    async editComment({ commit }, { context, comment }) {
        const response = await editComment(context, comment);
        commit(types.UPDATE_COMMENT_IN_POST, response.data);
        return { comment: response.data };
    },
    propagateIdentityChange({ commit }, { user, isAnonymous }) {
        commit(types.PROPAGATE_IDENTITY_CHANGE, {
            user,
            isAnonymous,
        });
    },
    async reportComment({ commit }, { context, commentId, reason, additional }) {
        await reportComment(context, commentId, reason, additional);
        commit(types.SET_REPORTED_COMMENT, { commentId });
    },
    setPost({ commit }, post) {
        commit(types.SET_POST, post);
    },
    async toggleBestComment({ commit }, { context, commentId }) {
        await toggleBestComment(context, commentId);
        commit(types.TOGGLE_BEST_COMMENT, commentId);
    },
};

const getters = {
    post: (state) => {
        return state.post;
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
