import { EventBus, EventBusEvent } from '@/plugins/eventBus';
import { type Plugin } from 'vuex';

/**
 * Handles store actions for certain event bus events.
 *
 * As importing the store in certain files create circular dependencies,
 * this is a helpful system to still trigger store actions outside of vue files.
 */
const eventBusPlugin: Plugin<unknown> = (store) => {
    EventBus.on(EventBusEvent.PROMPT_LOGIN, () => store.dispatch('ui/openLogin'));

    EventBus.on(EventBusEvent.NOTIFY_INFO, (content, options) =>
        store.dispatch('flashMessages/showDefault', { content, ...options }),
    );
    EventBus.on(EventBusEvent.NOTIFY_ERROR, (content, options) =>
        store.dispatch('flashMessages/showError', { content, ...options }),
    );
    EventBus.on(EventBusEvent.NOTIFY_SUCCESS, (content, options) =>
        store.dispatch('flashMessages/showSuccess', { content, ...options }),
    );
};

export default eventBusPlugin;
