import { i18n } from '@/plugins/i18n';

function isNetworkError(error) {
    return error.response === undefined && error.message === 'Network Error';
}

/**
 * This interceptor provides a common error response for network errors
 */
export default {
    handleResponseError(error) {
        if (isNetworkError(error)) {
            // eslint-disable-next-line no-param-reassign
            error.response = {
                data: { message: i18n.t('errors.networkError') },
                status: null,
            };
        }

        return Promise.reject(error);
    },
};
