<template>
    <BottomSheet class="w-full max-w-xl">
        <!-- @slot custom menu. Same interface as in the Menu component  -->
        <slot
            name="menu"
            :is-sheet="true"
            :is-shown="true"
            :hide="close"
        >
            <div
                class="text-xl pt-5 pb-4 px-7 leading-7 font-medium"
                v-text="menuTitle"
            ></div>
            <!-- this template shares similarities with the Menu component -->
            <ul class="flex flex-col divide-y divide-grey-200 light-theme pb-4">
                <li
                    v-for="({ onclick, ...option }, i) in options"
                    :key="i"
                    :data-testid="option.id ? `menu-item-${option.id}` : undefined"
                >
                    <!-- @slot custom menu item that applies to all options -->
                    <slot
                        name="menu-item"
                        :option="option"
                    >
                        <!-- @slot custom menu item that applies to a specific option -->
                        <slot
                            :name="`menu-item-${option.id ?? i}`"
                            :option="option"
                        >
                            <component
                                :is="option.is ?? 'menu-item'"
                                v-bind="option"
                                @click="clicked(onclick, $event)"
                            />
                        </slot>
                    </slot>
                </li>
            </ul>
        </slot>
    </BottomSheet>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import { defineComponent } from 'vue';
import ModalMixin from '@/mixins/modalMixin';
import BottomSheet from '@/components/BottomSheet';
import MenuItem from '@/components/MenuItem';
import type { MenuOption } from '@/components/Menu/Menu';

/**
 * This component is based on BottomSheet component to render a sheet that can be used to display a list of options.
 * The menu is rendered by using Modal component and will be closed once an item is clicked/clicking away
 * Title falls into `Options` when not provided.
 */
export default defineComponent({
    name: 'BottomSheetMenu',
    components: {
        BottomSheet,
        MenuItem,
    },
    i18nOptions: {
        namespaces: ['common'],
    },
    extends: ModalMixin,
    props: {
        /**
         * Array of menu options.
         */
        options: {
            type: Array as PropType<MenuOption[]>,
            default: () => [],
        },
        /**
         * Title of the menu
         */
        title: {
            type: String,
            default: null,
        },
    },
    computed: {
        menuTitle(): string {
            return this.title || this.$t('bottomSheet.title');
        },
    },
    methods: {
        /** Click event handler for the selected option. */
        clicked(onclick: undefined | CallableFunction, $event: MouseEvent): void {
            // @ts-ignore ts cannot detect close method
            this.close(onclick?.($event));
        },
    },
});
</script>
<style lang="scss" scoped>
:deep(.menu-item) {
    @apply px-8;
}
</style>
