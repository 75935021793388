const features = {
    backToSchool: import.meta.env.MIX_FEATURE_BACK_TO_SCHOOL === 'true',
    careerTracking: import.meta.env.MIX_FEATURE_CAREER_TRACKING === 'true',
    AIContentGeneration: import.meta.env.MIX_FEATURE_AI_CONTENT_GENERATION === 'true',
    guestUploads: import.meta.env.MIX_FEATURE_GUEST_UPLOADS === 'true',
    chats: import.meta.env.MIX_CHATS_FEATURE_ENABLED === 'true',
    anonymityIdentity: import.meta.env.MIX_ANONYMITY_ENABLED === 'true',
    callouts: import.meta.env.MIX_CALLOUTS_ENABLED === 'true',
    isCeApplicationOpen: import.meta.env.MIX_COURSE_EXPERT_APPLICATIONS === 'true',
    newUploadPage: import.meta.env.MIX_NEW_UPLOAD_PAGE_ENABLED === 'true',
};

export type FeatureName = keyof typeof features;

export const getAllFeatures = () => features;

export const featureIsEnabled = (feature: FeatureName) => features[feature] || false;
