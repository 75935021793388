import axios from 'axios';
import { i18n } from '@/plugins/i18n';
import { HTTP_STATUS } from '@/utils/networkUtils';
import { notifyError } from '@/components/FlashMessages';

/**
 * Determines the error key for some error types to easily group them.
 * @param {*} error
 * @returns
 */
function getErrorKey(error) {
    switch (error.response?.status) {
        case null:
            return 'network-error';
        case 503:
            return 'maintenance';
        default:
            return null;
    }
}

/**
 * Indicates whether we should report an error with given http status to our error logging
 * @param {*} error
 * @returns bool
 */
function shouldReportHttpStatus(error) {
    switch (error?.response?.status) {
        // network errors have a network status of 'null'
        case null:
        case HTTP_STATUS.UNAUTHORIZED:
        case HTTP_STATUS.FORBIDDEN:
        case HTTP_STATUS.TOO_MANY_REQUESTS:
            return false;
        default:
            return true;
    }
}

/**
 * Creates flash message for axios error
 * @param {*} error - axios error object
 */
function displayError(error) {
    const data = error.response?.data;

    // our new api has a new error format
    const apiContent = data?.error?.error_user_msg || data?.error?.message;

    // error is more specific for request validations, so we should process that first
    // Otherwise, error and message are somewhat used interchangeably
    const content = apiContent || data?.error || data?.message || i18n.t('errors.unexpectedError');

    notifyError(content, { key: getErrorKey(error) });
}

/**
 * Handles unhandled axios errors and invokes flash messages.
 * @param {*} error - axios error object
 * @param {*} notifyUser - whether the user should receive a flash message for the error
 * @returns {boolean} true if error was handled and doesn't need to be processed further
 */
export function handleAxiosError(error, notifyUser = false) {
    if (!error?.isAxiosError) return false;

    // don't further process cancelled/aborted requests
    if (axios.isCancel(error)) return true;

    if (notifyUser) {
        displayError(error);
    }

    return !shouldReportHttpStatus(error);
}

/**
 * Registers global axios error handler that catches all unhandled responses
 */
export function registerGlobalFallbackErrorHandler() {
    const oldHandler = window.onunhandledrejection;

    window.onunhandledrejection = (event) => {
        if (handleAxiosError(event.reason, true)) {
            event.stopImmediatePropagation();
            event.preventDefault();
        }

        if (event.bubbles) oldHandler(event);
    };
}
