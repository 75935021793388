import { handleAxiosError } from '@/api/utils/error';
import type { EventHint, ErrorEvent } from '@sentry/types';

/**
 * This processor filters axios errors similar to our global axios error handler,
 * in case they were reported directly to sentry.
 * But it does not notify the user.
 */
export default function axiosProcessor(event: ErrorEvent, { originalException: error }: EventHint) {
    if (handleAxiosError(error)) return null;

    return event;
}
