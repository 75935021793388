import type ziggy from 'ziggy-js';

declare global {
    interface Window {
        route: typeof ziggy;
    }
}

/**
 * This helper function returns the path of a route and converts parameters for vue-router usage.
 * {param} --> :param
 * {param?} --> :param?
 * {param:substitute} --> :param
 *
 * @param {string} routeName
 * @returns
 */
export function getPath(routeName: string) {
    return (
        window
            .route(routeName)
            // @ts-ignore urlBuilder is not exposed publicly by ziggy-js
            .urlBuilder?.path.replace(/\{([A-Za-z0-9_]+\?*)(:[A-Za-z0-9_]+)*\}/g, ':$1') as string
    );
}

// Ziggy's route helper is injected into the window by the back end
export default window.route;
