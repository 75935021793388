export enum Locale {
    DE = 'de',
    EN = 'en',
    EN_GB = 'en-GB',
}

export const availableLocales = [Locale.DE, Locale.EN, Locale.EN_GB];

export const defaultLocale = Locale.EN;

export const defaultNamespace = 'common';
