import store from '@/store';
import type { ErrorEvent } from '@sentry/types';

/** enriches sentry event data with user data */
export default function enrichUserDataProcessor(event: ErrorEvent) {
    /* eslint-disable camelcase, no-param-reassign */
    const user = store.getters['auth/user'];
    const is_admin = store.getters['auth/isAdmin'];
    const is_verified = store.getters['auth/isVerified'];
    const is_course_expert = store.getters['auth/isCourseExpert'];

    event.tags = {
        ...(event.tags || {}),
        logged_in: !!user,
        is_admin,
        is_verified,
        is_course_expert,
        screen_size: store.getters['ui/screenSize'],
        locale: store.getters['ui/getLocale'],
    };

    if (user) {
        // see: https://develop.sentry.dev/sdk/event-payloads/user/
        event.user = {
            id: user.userid,
            ip_address: '{{auto}}',
            is_admin,
            is_verified,
            is_course_expert,
        };
    }

    return event;
    /* eslint-enable camelcase, no-param-reassign */
}
